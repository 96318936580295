.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &__item {
      list-style-type: none;
      width: 35px;
      height: 35px;
      padding: 10px 0;
      text-align: center;
      vertical-align: middle;
      background-color: #ffffff;

      &.arrow {
        font-size: 20px;
        line-height: 23px;
      }
      cursor: pointer;
      &--active {
        font-weight: bold;
        color: #4a4a4a;
      }
      &--disabled {
        color: #d4d2d2;
        cursor: not-allowed;
      }
    }
  }