@import '@angular/material/theming';
$color-theme-primary: #0074d3;
$color-white: #fff;
@include mat-core();

$my-primary: (
  50: $color-theme-primary,
  100: $color-theme-primary,
  200: $color-theme-primary,
  300: $color-theme-primary,
  400: $color-theme-primary,
  500: $color-theme-primary,
  600: $color-theme-primary,
  700: $color-theme-primary,
  800: $color-theme-primary,
  900: $color-theme-primary,
  A100: $color-theme-primary,
  A200: $color-theme-primary,
  A400: $color-theme-primary,
  A700: $color-theme-primary,
  contrast: (
    50: $color-white,
    100: $color-white,
    200: $color-white,
    300: $color-white,
    400: $color-white,
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
    A100: $color-white,
    A200: $color-white,
    A400: $color-white,
    A700: $color-white,
  ),
);

$custom-primary-color: mat-palette($my-primary);
$coruscant-admin-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-primary-color, $custom-primary-color, $coruscant-admin-warn);

@include angular-material-theme($custom-theme);

.mat-tooltip {
  position: relative;
  width: 220px;
  background: white;
  color: #000 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  padding: 8px 15px !important;
  text-align: center;
  border-radius: 5px !important;
  font-weight: 400;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: inherit !important;
}

.mat-tooltip-panel-above {
  .mat-tooltip {
    &::after {
      position: absolute;
      content: ' ';
      top: 100%;
      left: 50%;
      margin-left: -13px;
      margin-top: -6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 13px 0 13px;
      border-color: #fff transparent transparent transparent;

      @media (max-width: 1199px) {
        margin-left: 75px;
      }

      @media (max-width: 980px) {
        display: none;
      }
    }

    @media (max-width: 775px) {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.mat-tooltip-panel-below {
  .mat-tooltip {
    &::after {
      position: absolute;
      content: ' ';
      top: 100%;
      left: 50%;
      margin-left: -13px;
      margin-top: -47px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 13px 0 13px;
      border-color: #fff transparent transparent transparent;
      transform: rotate(-180deg) translate(0, 4px);

      @media (max-width: 1199px) {
        margin-left: 75px;
      }

      @media (max-width: 980px) {
        display: none;
      }
    }

    @media (max-width: 775px) {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
