@import './assets/styles/material';
@import './assets/styles/global';

/************************************************************************************************************
   Web Fonts
************************************************************************************************************/
@font-face {
  font-family: 'SamsungOne-400';
  src: url('assets/fonts/SamsungOne-400.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOne-500';
  src: url('assets/fonts/SamsungOne-500.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOne-700';
  src: url('assets/fonts/SamsungOne-700.woff') format('woff');
}

@font-face {
  font-family: 'SamsungOne';
  src: url('assets/fonts/SamsungOne-400.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'SamsungOne';
  src: url('assets/fonts/SamsungOne-500.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'SamsungOne';
  src: url('assets/fonts/SamsungOne-700.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'SamsungOneLatin-200';
  src: url('assets/fonts/SamsungOneLatin-200.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOneLatin-300';
  src: url('assets/fonts/SamsungOneLatin-300.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOneLatin-400';
  src: url('assets/fonts/SamsungOneLatin-400.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOneLatin-500';
  src: url('assets/fonts/SamsungOneLatin-500.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOneLatin-600';
  src: url('assets/fonts/SamsungOneLatin-600.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOneLatin-700';
  src: url('assets/fonts/SamsungOneLatin-700.woff') format('woff');
}

@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-200.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-300.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-400.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-500.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-600.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'SamsungOneLatin';
  src: url('assets/fonts/SamsungOneLatin-700.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'SamsungSharpSans-Regular';
  src: url('assets/fonts/SamsungSharpSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'SamsungSharpSans-Medium';
  src: url('assets/fonts/SamsungSharpSans-Medium.woff') format('woff');
}
@font-face {
  font-family: 'SamsungSharpSans-Bold';
  src: url('assets/fonts/SamsungSharpSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SamsungSharpSans';
  src: url('assets/fonts/SamsungSharpSans-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'SamsungSharpSans';
  src: url('assets/fonts/SamsungSharpSans-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'SamsungSharpSans';
  src: url('assets/fonts/SamsungSharpSans-Bold.woff') format('woff');
  font-weight: 700;
}

/************************************************************************************************************
   Setting
************************************************************************************************************/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: 'SamsungOne', 'SamsungOneLatin', Arial, sans-serif;
  line-height: 1.4em;
  color: #252525;
  font-size: 16px;
}

html,
body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  background-color: #f2f2f2;
}
ul,
ol {
  list-style: none;
}
img,
button,
fieldset {
  border: 0 none;
}
button {
  background: none;
}
button * {
  position: relative;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
table {
  width: 100%;
  border-spacing: 0;
}
em,
address {
  font-style: normal;
}
a,
u {
  text-decoration: none !important;
}
textarea {
  resize: none;
}
fieldset,
legend {
  border: 0;
}
legend {
  height: 0;
  visibility: hidden;
}
input::-ms-clear {
  display: none;
}
input,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a,
button,
select {
  cursor: pointer;
}
a,
button,
button:focus,
input,
select,
textarea {
  outline: 0;
}
// body.loading {overflow: hidden;}

.cdk-overlay-pane > .mat-dialog-container {
  position: relative;
  border-radius: 30px;
  padding: 50px;
}

.center {
  display: flex;
  justify-content: center;
}

.button__spacing {
  margin-right: 5px;
  margin-bottom: 10px;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  .close-icon {
    width: 20px;
    height: 20px;
  }
}
